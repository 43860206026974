export const SN_USER = 'user'
export const USER_TOKEN_CHANGED = SN_USER + '/TOKEN_CHANGED'
export const USER_END_SESSION = SN_USER + '/END_SESSION'
export const USER_INFO_UPDATED = SN_USER + '/INFO_UPDATED'
export const USER_CURRENT_FACILITY_UPDATED = SN_USER + '/CURRENT_FACILITY_UPDATED'
export const USER_INSTANCE_URL_UPDATED = SN_USER + '/INSTANCE_URL_UPDATED' 
export const USER_SORTBY_UPDATED = SN_USER + '/SORTBY_UPDATED'
export const USER_PWA_STATE_UPDATED = SN_USER + '/PWA_STATE_UPDATED'
export const USER_CURRENT_ECOM_STORE_UPDATED = SN_USER + '/CURRENT_ECOM_STORE_UPDATED'
export const USER_PERMISSIONS_UPDATED = SN_USER + '/PERMISSIONS_UPDATED'
